import React from "react";

import GlassNavBar from "../../components/ui/GlassNavBar/GlassNavBar";
import GlassCover from "../../components/ui/GlassCover/GlassCover";
import GlassTeam from "../../components/ui/GlassTeam/GlassTeam";
import GlassProducts from "../../components/ui/GlassProducts/GlassProducts";
import GlassFooter from "../../components/ui/GlassFooter/GlassFooter";
import GlassValues from "../../components/ui/GlassValues/GlassValues";
import GlassContact from "../../components/ui/GlassContact/GlassContact";
import GlassServices from "../../components/ui/GlassServices/GlassServices";
import GlassProjects from "../../components/ui/GlassProjects/GlassProjects";

const LandingGlassPage = () => {
  return (
    <>
      <GlassNavBar />
      <GlassCover />
      <GlassProducts />
      <GlassTeam />
      <GlassValues/>
      <GlassContact />
      <GlassServices />
      <GlassProjects />
      <GlassFooter />
    </>
  );
};

export default LandingGlassPage;

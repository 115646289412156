import {HeroUIProvider} from "@heroui/react";
import AppRouters from "./routes/AppRouters";

function App() {
  return (
    <HeroUIProvider>
      <AppRouters/>
    </HeroUIProvider>
  );
}

export default App;

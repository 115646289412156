import React from "react";

import { Card, CardHeader, Image } from "@heroui/react";

import landingPageCover from "../../../resources/images/services/landing.jpeg";
import invoiceCover from "../../../resources/images/services/invoice.jpg";
import salesPointCover from "../../../resources/images/services/point.jpg";
import crmCover from "../../../resources/images/services/crm.jpg";
import erpCover from "../../../resources/images/services/erp.jpg";

const GlassServices = () => {
  return (
    <div className="bg-gray-50 pt-24 sm:pt-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <p className="hover:scale-105 transition-transform duration-300 mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl">
          Conoce nuestros servicios
        </p>
        <h2 className="mt-6 mx-auto max-w-lg text-center text-base/7 font-semibold text-indigo-600">
          Algunas de las opciones que tenemos para ti!
        </h2>

        <div className="mt-11 gap-2 grid grid-cols-12 grid-rows-2 px-8">
          <Card className="hover:scale-95 transition-transform duration-300 col-span-12 sm:col-span-4 h-[300px]">
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
              <p className="text-tiny text-white/50 uppercase font-bold">
                Dale visibilidad a tu proyecto o negocio con una página
                llamativa y accesible
              </p>
              <h4 className="text-white font-medium text-large">
                Landing page
              </h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-full object-cover"
              src={landingPageCover}
            />
          </Card>
          <Card className="hover:scale-95 transition-transform duration-300 col-span-12 sm:col-span-4 h-[300px]">
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
              <p className="text-tiny text-white/60 uppercase font-bold">
                Implementa la automatización de facturas dentro de tu negocio
              </p>
              <h4 className="text-white font-medium text-large">
                Sistema de Facturación
              </h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-full object-cover"
              src={invoiceCover}
            />
          </Card>
          <Card className="hover:scale-95 transition-transform duration-300 col-span-12 sm:col-span-4 h-[300px]">
            <CardHeader className="absolute z-10 top-1 flex-col !items-start">
              <p className="text-tiny text-white/60 uppercase font-bold">
                Todo el control que tu negocio necesita
              </p>
              <h4 className="text-white font-medium text-large">
                Punto de venta
              </h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Card background"
              className="z-0 w-full h-full object-cover"
              src={salesPointCover}
            />
          </Card>
          <Card
            isFooterBlurred
            className="hover:scale-95 transition-transform duration-300 w-full h-[300px] col-span-12 sm:col-span-5"
          >
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
              <p className="mt-5 text-black/70 text-tiny uppercase font-bold">
                Gestión de clientes, seguimiento de ventas, campañas, etc.
              </p>
              <h4 className="text-black font-medium text-2xl">
                CRM personalizado
              </h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Card example background"
              className="z-0 w-full h-full scale-125 -translate-y-6 object-cover"
              src={crmCover}
            />
          </Card>
          <Card
            isFooterBlurred
            className="hover:scale-95 transition-transform duration-300 w-full h-[300px] col-span-12 sm:col-span-7"
          >
            <CardHeader className="absolute z-10 top-1 flex-col items-start">
              <p className="text-tiny text-white/60 uppercase font-bold">
                Control de inventario, recursos humanos, compras, logística,
                etc.
              </p>
              <h4 className="text-white/90 font-medium text-xl">ERP ligero</h4>
            </CardHeader>
            <Image
              removeWrapper
              alt="Relaxing app background"
              className="z-0 w-full h-full object-cover"
              src={ erpCover }
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GlassServices;

import React from "react";

import { Image } from "@heroui/react";

import glassSystemsLogo from "../../../resources/images/logo/logo-gs.png";
import glassSystemsCover from "../../../resources/images/generals/cover.jpg";

const GlassCover = () => {
  return (
    <div className="bg-gray-50">
      <div
        className="mb-28 bg-cover bg-center h-96"
        style={{ backgroundImage: `url(${glassSystemsCover})` }}
      >
        <div className="flex justify-center mb-8">
          <Image
            className="mt-60 p-2 bg-white rounded-full w-44 hover:scale-105 transition-transform duration-300 md:w-64 md:mt-60"
            alt="Glass Systems Logo"
            src={glassSystemsLogo}
          />
        </div>
      </div>
      <div className="pb-16">
        <h2 className="text-center text-base/7 font-semibold text-indigo-600 italic">
          "El traje a la medida que tu empresa necesita"
        </h2>
        <p className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 hover:scale-105 transition-transform duration-300 sm:text-5xl">
          Glass Systems
        </p>
      </div>
    </div>
  );
};

export default GlassCover;

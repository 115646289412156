import React from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardFooter, Image } from "@heroui/react";

import factulabCover from "../../../resources/images/projects/factulab.jpeg";
import bbvaCover from "../../../resources/images/projects/logo-bbva.webp";

const projectsList = [
  {
    title: "FactuLab",
    img: factulabCover,
    description: "Generación de facturas",
    useNavigate: true,
    href: "/factulab/login"
  },
  {
    title: "BBVA",
    img: bbvaCover,
    description: "Colaborador",
    useNavigate: false,
    href: "https://www.bbva.mx/"
  },
];

const GlassProjects = () => {

  const navigate = useNavigate();

  const hanldeCardNavigate = ({ useNavigate, href }) => {
    if (useNavigate) {
        navigate(href);
    } else {
        window.open(href, '_blank');
    }
  }

  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <p className="hover:scale-95 transition-transform duration-300 mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl">
          Glass Projects
        </p>
        <h2 className="mt-6 mx-auto max-w-lg text-center text-base/7 font-semibold text-indigo-600">
          Conoce un poco de los proyectos que tenemos en vuelo y de los que
          estamos construyendo
        </h2>

        <div className="mt-8 gap-4 grid content-center grid-cols-2 sm:grid-cols-4">
          {projectsList.map((item, index) => (
            /* eslint-disable no-console */
            <Card
              className="hover:scale-95 transition-transform duration-300"
              key={index}
              isPressable
              shadow="sm"
              onPress={() => hanldeCardNavigate(item)}
            >
              <CardBody className="overflow-visible p-0">
                <Image
                  alt={item.title}
                  className="w-full object-cover h-[140px]"
                  radius="lg"
                  shadow="sm"
                  src={item.img}
                  width="100%"
                />
              </CardBody>
              <CardFooter className="text-small justify-between">
                <b>{item.title}</b>
                <p className="text-default-500">{item.description}</p>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlassProjects;

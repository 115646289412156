import React from "react";

import {
  Card,
  CardHeader,
  Image,
  Divider,
  CardBody,
  CardFooter,
  Link,
} from "@heroui/react";

const GlassContactCard = ({ contactInfoDetail }) => {
  const {
    icon,
    contactName,
    contactDirection,
    description,
    hrefDescription,
    href,
  } = contactInfoDetail;

  return (
    <Card className="py-4 max-w-[400px] hover:scale-105 transition-transform duration-300 sm:max-w-none col-span-3 lg:col-span-1">
      <CardHeader className="flex gap-3">
        <Image
          alt="heroui logo"
          height={40}
          radius="sm"
          src={icon}
          width={40}
        />
        <div className="flex flex-col">
          <p className="text-md">{contactName}</p>
          <p className="text-small text-default-500">{contactDirection}</p>
        </div>
      </CardHeader>
      <Divider />
      <CardBody>
        <p>{description}</p>
      </CardBody>
      <Divider />
      <CardFooter>
        <Link isExternal showAnchorIcon href={href}>
          {hrefDescription}
        </Link>
      </CardFooter>
    </Card>
  );
};

export default GlassContactCard;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import PrivadeRoutes from "./PrivadeRoutes";
// import PublicRoutes from "./PublicRoutes";
import LandingGlassPage from "../pages/LandingGlassPage/LandingGlassPage";
import FactuLabLogin from "../pages/FactuLab/FactuLab/FactuLabLogin/FactuLabLogin";

const AppRouters = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingGlassPage />} />
        <Route path="/*" element={<LandingGlassPage />} />
        <Route path="/factulab/login" element={<FactuLabLogin />} >
        </Route>
        
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouters;

import React from "react";

import whatsAppContactIcon from "../../../resources/images/contact/whatsapp.ico";
import emailContactIcon from "../../../resources/images/contact/correo.ico";
import instagramContactIcon from "../../../resources/images/contact/instagram.ico";

import GlassContactCard from "./GlassContactCard/GlassContactCard";

const glassSystemsConctactOptions = [
  {
    icon: whatsAppContactIcon,
    contactName: "Whatsapp",
    contactDirection: "+52 55 3910 4022",
    description: "Puedes tener la comunicación más directa y tendras nuestra respuesta lo mas pronto posible",
    hrefDescription: "Enviar Whatsapp",
    href: "https://wa.me/5539104022?text=Hola%20quiero%20más%20información"
  },
  {
    icon: emailContactIcon,
    contactName: "E-mail",
    contactDirection: "contacto-glass@glasssystems.com",
    description: "Formaliza tu solicitud a traves de un correo en el cual recibiras respuesta a la brevedad.",
    hrefDescription: "Enviar Mail",
    href:"mailto:contacto-glass@glasssystems.comm"
  },
  {
    icon: instagramContactIcon,
    contactName: "Instagram",
    contactDirection: "@_glasssystems",
    description: "Animate a conocer mas de nuestros productos, de nosotros y de nuestra empresa.",
    hrefDescription: "Visitar Ig",
    href: "https://www.instagram.com/antoniowolfsaucedo/"
  },
];

const GlassContact = () => {
  return (
    <div className="bg-gray-50 pt-24 sm:pt-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <p className="mx-auto mt-2 max-w-lg text-center text-4xl font-semibold tracking-tight text-balance text-gray-950 sm:text-5xl">
          Glass Contact
        </p>
        <h2 className="mt-6 mx-auto max-w-lg text-center text-base/7 font-semibold text-indigo-600">
          Acercate con confianza y cualquiera de nuestros expertos te dara
          respuesta brevemente para comenzar a construir tu solución
        </h2>

        <div className="mt-5 grid gap-4 place-content-center sm:grid-cols-6 lg:grid-cols-3">
          {glassSystemsConctactOptions.map((contactInfoDetail) => (
            <GlassContactCard contactInfoDetail={contactInfoDetail} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlassContact;
